import React from 'react';
import './Home.css'; // Asume que los estilos están en Home.css

function Home() {
  return (
    <div className="home">
      <header className="header">
        <h1>Tzero2</h1>
        <div className="menu-icon">≡</div>
      </header>

      <nav className="navigation">
        <button>Agregar</button>
        <button>Ver</button>
        <button>Editar</button>
        <button>Buscar</button>
        <button>Eliminar</button>
        <button>Imprimir</button>
      </nav>
    </div>
  );
}

export default Home;
