// components/LoginForm.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginForm() {
  const [usuario, setUsuario] = useState('');
  const [contraseña, setContraseña] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (usuario === 'admin' && contraseña === '12') {
      alert('Inicio de sesión exitoso');
      navigate('/home'); // Ruta
    } else {
      alert('Usuario o contraseña incorrectos');
    }
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <h2>BIENVENIDO</h2>
        <h3>Ingresa tus credenciales</h3>
        <div>
          <label>Usuario:</label>
          <input type="text" value={usuario} onChange={(e) => setUsuario(e.target.value)} />
        </div>
        <div>
          <label>Contraseña:</label>
          <input type="password" value={contraseña} onChange={(e) => setContraseña(e.target.value)} />
        </div>
        <button type="submit">Iniciar Sesión</button>

          <h4>¿Olvidaste tú contraseña?</h4>

      </form>
    </div>
  );
}

export default LoginForm;